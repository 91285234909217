jQuery(document).ready(function ($) {
    // Cacher filtres VS au chargement
    if ($("#post_type").val() == "product") {
        $(".VS:not(.hidden)").hide();
    }

    if ($("#post_type").val() == "voyage-scolaire") {
        $(".SL:not(.hidden)").hide();
    }

    // Plus de criteres
    $("#plus-de-critere").click(function (e) {
        e.preventDefault();
        postType = $("#type_sejour").val();

        if (postType == "product") {
            if ($("#plus-de-critere span").data("plus")) {
                $("#search_form select.hidden.SL").show();
                $("#plus-de-critere span")
                    .empty()
                    .append('<i class="mr-3 far fa-minus-circle"></i>')
                    .data("plus", false);
            } else {
                $("#search_form select.hidden.SL").hide();
                $("#plus-de-critere span")
                    .empty()
                    .append('<i class="mr-3 far fa-plus-circle"></i>')
                    .data("plus", true);
            }
        } else {
            if ($("#plus-de-critere span").data("plus")) {
                $("#search_form select.hidden.VS").show();
                $("#plus-de-critere span")
                    .empty()
                    .append('<i class="mr-3 far fa-minus-circle"></i>')
                    .data("plus", false);
            } else {
                $("#search_form select.hidden.VS").hide();
                $("#plus-de-critere span")
                    .empty()
                    .append('<i class="mr-3 far fa-plus-circle"></i>')
                    .data("plus", true);
            }
        }
    });

    // Changement type séjour (SL ou VS)
    $("#type_sejour").change(function () {
        postType = $(this).val();
        typeSejourChange(postType);
        majSelecteRechercheAll();
    });

    $("#encadrement").change(function () {
        majSelecteRechercheAll();
    });
    $("#destination").change(function () {
        majSelecteRechercheAll();
    });
    $("#destination_vs").change(function () {
        majSelecteRechercheAll();
    });
    if ($('body').hasClass('tax-destination')) {
        majSelecteRechercheAll();
    }
    if ($('body').hasClass('post-type-archive-voyage-scolaire')) {
        typeSejourChange('voyage-scolaire');
        var classList = $("body").attr("class");
        var classArr = classList.split(/\s+/);
        $.each(classArr, function(index, value){
            if (value.substring(0, 4) == 'term') {
                if ($("#destination_vs option[value='" + value.substring(5) + "']").length > 0) {
                    $("#destination_vs").val(value.substring(5));
                }
            }
        });
        majSelecteRechercheAll();
    }
    if ($('body').hasClass('tax-destination_vs')) {
        typeSejourChange('voyage-scolaire');
        var classList = $("body").attr("class");
        var classArr = classList.split(/\s+/);
        $.each(classArr, function(index, value){
            if (value.substring(0, 4) == 'term') {
                if ($("#destination_vs option[value='" + value.substring(5) + "']").length > 0) {
                    $("#destination_vs").val(value.substring(5));
                }
            }
        });
        majSelecteRechercheAll();
    }
    if ($('body').hasClass('post-type-archive')) {
        if ($('#type_sejour').val() == 'product') {
            majSelecteRechercheAll();
        }
        if ($('#type_sejour').val() == 'voyage-scolaire') {
            majSelecteRechercheAll();
        }
    }

    function typeSejourChange(postType) {
        $("#post_type").val(postType);
        $("#plus-de-critere span").data("type", postType);

        //
        if (postType == "product") {
            $('.SL').show();
            $('.VS').hide();
            $("#destination_vs").val("0");
        } else {
            $('.SL').hide();
            $('.VS').show();
            $("#destination").val("0");
        }
        //
        $("#search_form select.hidden").hide();
        $("#plus-de-critere span")
            .empty()
            .append('<i class="mr-3 far fa-plus-circle"></i>')
            .data("plus", true);
        //$(".SL ,.VS ").val("0");
    }

    function majSelecteRecherche(type, value) {
        jQuery('#search_form').addClass('loading');
        var filters = 'action=majrecherche&type=' + type + '&value=' + value;
        $.ajax({
            url: '/wp/wp-admin/admin-ajax.php',
            data: filters,
            type: "GET",
            success: function success(data) {
                var json = $.parseJSON(data);
                for (var i = 0; i < json.length; i++) {
                    jQuery('#' + json[i].select).html(json[i].html);
                }
                jQuery('#search_form').removeClass('loading');
            }
        });
    }

    function majSelecteRechercheAll() {
        var type = $("#type_sejour").val();
        var encadrement = $("#encadrement").val();
        if (type == 'voyage-scolaire') {
            type = 'VS';
            var destination = $("#destination_vs").val();
        } else {
            type = 'SL';
            var destination = $("#destination").val();
        }
        jQuery('#search_form').addClass('loading');
        var filters = 'action=majrecherche&type=' + type + '&encadrement=' + encadrement + '&value=' + destination;
        $.ajax({
            url: '/wp/wp-admin/admin-ajax.php',
            data: filters,
            type: "GET",
            success: function success(data) {
                var json = $.parseJSON(data);
                for (var i = 0; i < json.length; i++) {
                    jQuery('#' + json[i].select).html(json[i].html);
                }
                jQuery('#search_form').removeClass('loading');
            }
        });
    }

    //
    /*$("#search_form select.VS,#search_form select.SL").change(function () {
        this.form.submit();
    });*/

    // Scroll
    if (recherche) {
        $("html, body").animate(
            {
                scrollTop: $("#search-home").offset().top - $('#search-home').height() + 50,
            },
            500
        );
    }

    $('#search_form #age option').each(function() {
        var value = $(this).val();
        if (value.substring(0, 11) == 'a-partir-de') {
            $(this).hide();
        }
    });

});
